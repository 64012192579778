import { useEffect, useState } from "react";
import "./PlanPopup.css";
import { Modal, Button } from "react-bootstrap";
import WebService from "../../../Services/WebService";
import PaymentCards from "../PaymentCards/PaymentCards";
import { useSelector } from "react-redux";


export const PlanPopup = (props: any) => {
  const uat = useSelector((state: any) => window?.atob(state.uat.uat));
  const [show, setShow] = useState(false);
  const [action, setAction] = useState("");
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  useEffect(() => {
    if (uat === undefined || uat === null) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      var as = uat;
      if (uat != null && (uat === "BASIC_USER" || uat === "COMPLIANCE_OFFICER")) {
        setShow(true);
      }
    }
  }, [])

  return (
    <>
      {
        !showPaymentCard ?
          <Modal
            show={props.show}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header >
              <Modal.Title>{show ? "Info" : props.paymentStatus === "PENDING" ? "Please Enter Payment Information" : props.paymentStatus === "PAYMENT_DUE" ? "Payment Overdue" : props.paymentStatus === "EXPIRED" ? "Plan Expired" : "Trial Account"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {

                show ? "Contact Admin" : props.paymentStatus === "PENDING" || props.paymentStatus === "PAYMENT_DUE" ? `Your Invoice #${props.invoiceNo} is due.` : "One step remaining to start your trial period. Please enter your payment method"

              }
            </Modal.Body>
            <Modal.Footer>
              {show ? <Button variant="primary" onClick={() => {
                WebService.logout();
              }}>Logout</Button> : <Button variant="primary" onClick={() => setShowPaymentCard(true)}>Continue</Button>}
            </Modal.Footer>
          </Modal>
          :
          <PaymentCards
            trxId={props.trxId}
            action={action}
            show={showPaymentCard}
            type="PLAN"
            paymentStatus={props.paymentStatus}
            parentCallbackRefreshList={(data: any) => setShowPaymentCard(data)}
            parentCallbackIsClose={(data: any) => setShowPaymentCard(data)}
          />

      }

    </>
  );
};
