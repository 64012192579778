import { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import WebService from "../../../Services/WebService";
import { Label } from "../../Common/Label/Label";
import AIDatePicker from "../../Common/AIDatePicker/AIDatePicker";
import { useSelector } from "react-redux";

interface propsData {
  closeBlade: any;
}

const ComplianceActivityFilterBlade = (props: propsData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  var navigate = useNavigate();
  const [reviewerFlag, setReviewrFlag] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedReviewers, setSelectedReviewerList] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  var watchVariable = watch();
  useEffect(() => {
    watchVariable.startDate = startDate;
    setValue("startDate", startDate);
    watchVariable.endDate = endDate;
    setValue("endDate", endDate);
  }, []);
  const handleApply = () => {
    if (selectedReviewers.length === 0) {
      setReviewrFlag(true);
      return;
    } else {
      var userArr: any = [];
      var grpArr: any = [];

      selectedReviewers.forEach((reviewer: any) => {
        var obj = { id: reviewer.value };
        if (reviewer.applyType === "USER") {
          userArr.push(obj);
        } else {
          grpArr.push(obj);
        }
      });

      let obj = {
        userList: userArr,
        groupList: grpArr,
      };
      watchVariable.reviewers = obj;
      setReviewrFlag(false);
    }

    navigate("/reports/compliance-activity", {
      state: { filterObject: watchVariable },
    });

    props.closeBlade(false);
  };
  const onReviwerSelection = (selectedOptions: any) => {
    var arr: any = [];
    if (selectedOptions.length > 0) {
      selectedOptions.forEach((element: any, index: any) => {
        if (index === selectedOptions?.length - 1) {
          var obj = {
            "value": element.value,
            "label": element.label?.props?.children[1],
            "applyType": element.applyType
          }
          arr.push(obj);
        } else {
          arr.push(element);
        }

      });
    }
    setSelectedReviewerList(arr);
    if ((selectedReviewers.length = 0)) {
      setReviewrFlag(true);
    } else {
      setReviewrFlag(false);
    }
  };
  const handleUserCheckboxChange = (userId: any) => {
    setSelectedUsers((prevUsers: any) => {
      if (prevUsers.includes(userId)) {
        return prevUsers.filter((id: any) => id !== userId);
      } else {
        return [...prevUsers, userId];
      }
    });
  };


  const handleGroupCheckboxChange = (groupId: any) => {
    setSelectedGroups((prevGroups: any) => {
      if (prevGroups.includes(groupId)) {
        return prevGroups.filter((id: any) => id !== groupId);
      } else {
        return [...prevGroups, groupId];
      }
    });
  };
  const handleError = () => {
    if (selectedReviewers.length === 0) {
      setReviewrFlag(true);
    } else {
      setReviewrFlag(false);
    }
  };
  const onDateChange = (date: any, type: any) => {
    if (type === "startDate") {
      setStartDate(date);
      watchVariable.startDate = date;
      setValue("startDate", date);
    } else if (type === "endDate") {
      setEndDate(date);
      watchVariable.endDate = date;
      setValue("endDate", date);
    }
  };

  const handleSelectAll = (event: any, options: any) => {
    var arr: any[] = []
    options.map((opt: any, index: any) => {
      var obj = {
        "value": opt.value,
        "label": opt.label?.props?.children[1],
        "applyType": opt.applyType
      }
      arr.push(obj);
    })

    const updatedOptions = arr.map((option: any) => {
      return {
        ...option,
        isSelected: true,
      };
    });
    setSelectedReviewerList((prevSelectedReviewers) => [
      ...updatedOptions,
      ...prevSelectedReviewers.filter(
        (option) => option.applyType !== "GROUP" || option.applyType !== "USER"
      ),
    ]
    );

  };

  const fetchUsers = async (inputValue: any) => {
    try {
      var userResponse: any = [];
      userResponse = await WebService.getAPI({
        action: `client/users?keyword=${inputValue}`,
        body: { query: inputValue },
        access_token:access_token,
        t_id:t_id
      });
      return userResponse?.filter((user: any) => user.role_type !== "BASIC_USER").map((user: any) => ({
        value: user.id,
        label:
          (
            <label onChange={() => handleUserCheckboxChange(user.id)}>
              <input type="checkbox" className="me-1" />
              {user.first_name + " " + user.last_name}
            </label>
          ),
        applyType: "USER",
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  };
  const fetchGroups = async (inputValue: any) => {
    try {
      var groupResponse: any = [];
      groupResponse = await WebService.getAPI({
        action: `groups?keyword=${inputValue}`,
        body: { query: inputValue },
        access_token:access_token,
        t_id:t_id
      });
      return groupResponse?.map((group: any) => ({
        value: group.id,
        label: (
          <label onChange={() => handleGroupCheckboxChange(group.id)}>
            <input type="checkbox" className="me-1" />
            {group.group_name}
          </label>
        ),
        applyType: "GROUP",
      }));
    } catch (error) {
      console.error("Error fetching groups:", error);
      return [];
    }
  };

  const ReviewrsPromiseOption = async (inputValue: any) => {
    try {
      const [userOptions, groupOptions] = await Promise.all([
        fetchUsers(inputValue),
        fetchGroups(inputValue),
      ]);
      const applyTo = [];
      if (userOptions.length > 0) {
        applyTo.push({
          label: (
            <label className="cursor-pointer" onClick={(e) => handleSelectAll(true, userOptions)}>
              Select All Users
            </label>
          ),
          options: userOptions,
        });
      }

      if (groupOptions.length > 0) {
        applyTo.push({
          label: (
            <label className="cursor-pointer" onClick={(e) => handleSelectAll(true, groupOptions)}>
              Select All Groups
            </label>
          ),
          options: groupOptions,
        });
      }

      return applyTo;
    } catch (error) {
      console.error("Error loading options:", error);
      return [];
    }
  };


  return (
    <Form
      className="w-100 form-style"
      name="Verify"
      id="Verify"
      onSubmit={handleSubmit(handleApply, handleError)}
    >
      <div className="px-3 mt-3">
        <div className="d-flex gap-3 mb-1 mt-2">
          <Row>
            <Col>
              <AIDatePicker
                date={startDate}
                selectDate={(date: any) => onDateChange(date, "startDate")}
                maxDate={new Date()}
              />
            </Col>
            <Col>
              <AIDatePicker
                date={endDate}
                selectDate={(date: any) => onDateChange(date, "endDate")}
                maxDate={new Date()}
              />
            </Col>
          </Row>
        </div>
        <h4 className="font-18 font-medium mb-2">Include Reviewers</h4>
        <div className="mb-3">
          <AsyncSelect
            {...register("reviewers")}
            cacheOptions
            isMulti
            defaultOptions
            onChange={onReviwerSelection}
            loadOptions={ReviewrsPromiseOption}
            value={selectedReviewers}
            placeholder="Select reviewers"
          />
          {selectedReviewers.length === 0 && reviewerFlag ? (
            <Label
              title="Please select atleast one reviewer"
              modeError={true}
            />
          ) : (
            ""
          )}
        </div>
        <div>

        </div>

        <Button className="btn btn-brand-1 w-100 mt-0" type="submit" id="apply">
          Start Review
        </Button>
      </div>
    </Form>
  );
};
export default ComplianceActivityFilterBlade;