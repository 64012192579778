import { Badge, Button, Card, CloseButton, Col, FloatingLabel, Form, Modal, Offcanvas, OffcanvasBody, Row, Table } from "react-bootstrap";
import { RxReload } from "react-icons/rx";
import { useEffect, useState } from "react";
import WebService from "../../../Services/WebService";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { HiMiniFlag } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import NoDataFound from "../../../assets/images/empty_item.svg";
import TableLoader from "../../Common/TableLoader/TableLoader";
import AIPagination from "../../Common/Pagination/AIPagination";
import MessageViewBlade from "./MessageViewBlade";
import { CgClose } from "react-icons/cg";
import HelperService from "../../../Services/HelperService";
import moment from "moment";
import { toast } from "react-toastify";
import { BsFunnel } from "react-icons/bs";
import MessageConversationBlade from "./MessageConversationBlade";
import "./message-review.scss";
import { PiSealWarning } from "react-icons/pi";
import { useForm } from "react-hook-form";
import { Label } from "../../Common/Label/Label";
import AsyncSelect from "react-select/async";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import AuditLog from "../../AuditLog/AuditLog";
import auditLogIcon from "../../../assets/images/searchicon.svg";
import { TooltipCustom } from "../../Common/Tooltip/Tooltip";
import ReviewNamePopup from "../../EmailReview/ReviewNamePopup";
import UserContact from "../../SmsReview/UserContact";
interface propsData {
  viewObject?: any;
  changedObject?: any;
  keyWord?: any;
  excalated?: any;
  filterObject?: any;
  tab?: any;
  sendData?: any;
  automark?: any;
  isActive?: any;
  changeIsActive?: any;
  reviewTab?: any;
  reviewedCount?: any;
  totalCount?: any;
  reviewAllFlag?: any;
  smsFilterBlade?: any;
  filterFlag?: any;
  sendBulkReviewList?: any;
  changeInList?: any;
  filterTab?: any;
  smsTotalCount?: any
  filterFlagChange?: any;
  flagged?: any;
  flaggedDate?: any;
  selectedTab?: any;
  bulkExport?: any;
  clearFilterCallback?: any;
  isReviewAll?: any;
  isReviewed?: any;

}
var isFatchCallRunning = false;
const MessageTab = (props: propsData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const role = useSelector((state: any) => state.uR.role);
  const userId = useSelector((state: any) => state.uI.id);
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    formState: { errors: errors1 },
    clearErrors: clearErrors1,
    setError: setError1,
    reset: reset1,
    watch: watch1,
  } = useForm();
  let watchVariable1 = watch1();
  const [smsType, setSmsType] = useState("");
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [myContactpage, setMyContactPage] = useState(1);
  const [sortBy, setSortBy] = useState("date_time");
  const [orderBy, setOrderBy] = useState("DESC");
  const [clickable, setClickable] = useState("date_time");
  const [isActive, setIsActive] = useState(false);
  const [isConversation, setIsConversation] = useState(false);
  const [smsConversationData, setSmsConversationData] = useState<any>();
  const [smsList, setSMSList] = useState<any[]>([]);
  const [automark, setAutoMark] = useState(false);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [excalated, setEscalated] = useState(false);
  const [pagination, setPagination] = useState(100);
  const [refresh, setRefresh] = useState(false);
  const [deleteExclatedPostList, setDeleteExcalatedPost] = useState<any[]>([]);
  const [allSelect, setAllSelect] = useState(false);
  const [allSelectMyContext, setAllSelectMyContext] = useState(false);
  const [reviewedCount, setReviewedCount] = useState(0);
  const [bulkReviewList, setBulkReviewList] = useState<any[]>([]);
  const [myContextCheckList, setMyContextCheckList] = useState<any[]>([]);
  const [complainceUserLogAccess, setComplainceUserLogAccess] = useState(true);
  const [smsExportObj, setSmsExportObj] = useState<any>();
  const [myContextList, setMyContextList] = useState<any>([]);
  const [myContactCount, setMyContactCount] = useState<any>(0);
  const [bulkContactDelete, setBulkContactDelete] = useState<any>([]);
  const [showContactDelete, setShowContactDelete] = useState<any>(false);
  const [keyword1, setKeyword1] = useState<any>("");
  const [isShowDeleteBtn, setIsShowDeleteBtn] = useState<any>(false);
  const [contactLoader, setContactLoader] = useState<any>(false);
  const [showAddContactPopup, setShowAddContactPopup] = useState<any>(false);
  const [selectContactErr, setSelectContactErr] = useState<any>("");
  const [contactFlag, setContactFlag] = useState(false);
  const [userIdDelete, setUserIdDelete] = useState<any>();
  const [selectedContact, setSelectedContact] = useState<any>({
    id: "",
    value: "",
    label: "Select iMessage user",
  });
  let navigate = useNavigate();

  const [showAuditLog, setShowAuditLog] = useState(false);
  const [showReviewNamePopup, setShowReviewNamePopup] = useState<any>(false);
  const [reviewId, setReviewId] = useState<any>("");
  const [oldReviewId, setOldReviewId] = useState<any>(undefined);
  const [showMyContextPopup, setShowMyContextPopup] = useState<any>(false);

  useEffect(() => {
    var data1 = atob(localStorage.getItem("ucd") ?? "");
    if (role === "COMPLIANCE_OFFICER") {
      if (data1 === "" || data1 === undefined) {
        navigate("/access-denied");
      } else {
        var json = JSON.parse(data1);
        setAutoMark(json.reviewLogs);
        setComplainceUserLogAccess(json.reviewLogs);
      }
    }
  }, []);

  useEffect(() => {
    if (props.filterFlagChange) {
      if (smsType !== "") {
        props.filterFlagChange(true);
      } else {
        props.filterFlagChange(false);
      }
    }
  }, [smsType]);

  const handleShowFilterBlade = () => {
    props.smsFilterBlade(true);
  };
  const [allSelectForBulk, setallSelectForBulk] = useState(false);
  const [allSelectForMyContext, setallSelectForMyContext] = useState(false);
  const [groupName, setGroupName] = useState<any>("");
  const toggleViewSidebar = (isAtc: boolean) => {
    props.changeIsActive(isAtc, "SMS");
    setIsActive(isAtc);
  };

  const toggleConversationSlideBar = (data: any) => {
    setIsConversation(data);
  };

  useEffect(() => {
    if (props.excalated) {
      setEscalated(() => true);
    }
    if (props.changeInList && props.changeInList.idList?.length > 0) {
      var updatedEmailList = [...smsList];
      props.changeInList.idList.forEach((id: any) => {
        const index = updatedEmailList.findIndex(
          (emailItem: any) => emailItem.id === id
        );

        if (index !== -1) {
          let reviewClass = "";
          if (props.changeInList.markedAsFlag) {
            reviewClass = "flag";
          }
          if (props.changeInList.markedAsView) {
            reviewClass = reviewClass + " " + "mardk-as-viewed";
          }
          if (props.changeInList.escalateAndSend) {
            reviewClass = reviewClass + " " + "escalate-send";
          }
          updatedEmailList[index].markedAsFlag =
            props.changeInList.markedAsFlag;
          updatedEmailList[index].markedAsView =
            props.changeInList.markedAsView;
          updatedEmailList[index].escalateAndSend =
            props.changeInList.escalateAndSend;
          updatedEmailList[index].reviewClass = reviewClass;
          updatedEmailList[index].bulkReview = false;
          updatedEmailList[index].notes = props.changeInList.notes;
          updatedEmailList[index].check = false;
          updatedEmailList[index].bulkReview = false;
        }
      });
      setSMSList(updatedEmailList);
      toggleViewSidebar(false);
      setBulkReviewList([]);
      setAllSelect(false);
      setallSelectForBulk(false);
      setDeleteExcalatedPost([]);


    } else {
      if (JSON.stringify(props.filterObject) != '{}' && reviewId !== undefined) {
        getMessageList(1, reviewId);
        toggleViewSidebar(false);
      } else {
        setPage(1)
        getMessageList(1);
      }

    }

  }, [
    sortBy,
    orderBy,
    smsType,
    props.changeInList,
  ]);


  useEffect(() => {
    getMessageList(page, reviewId);
  }, [page]);

  useEffect(() => {
    if ((props.keyWord !== "" && props.keyWord !== undefined) && !props.isReviewAll && !props.excalated) {
      setOldReviewId(reviewId);
    } else {
      if (JSON.stringify(props.filterObject) !== '{}' && !props.isReviewAll && !props.excalated) {
        setOldReviewId(reviewId);
      } else {
        getMessageList(page);
      }
    }
  }, [props.keyWord]);

  useEffect(() => {
    if (JSON.stringify(props.filterObject) !== '{}' && !props.isReviewAll && !props.excalated) {
      setOldReviewId(reviewId);
    } else {
      getMessageList(page);
    }
  }, [props.filterObject]);


  useEffect(() => {
    if (oldReviewId !== undefined) {
      getReviewId();
    }
  }, [oldReviewId]);

  useEffect(() => {
    setAutoMark(props.automark);
  }, [props.automark]);

  useEffect(() => {
    toggleViewSidebar(false)
    getMessageList(1);
  }, [props.selectedTab]);

  const getReviewId = () => {
    if (!props.isReviewAll && !props.excalated) {
      const obj1: any = {
        page: 1,
        sortBy: "message_datetime",
        orderBy: orderBy,
        smsType: smsType,
        keyword: props.keyWord ?? "",
      };
      setSmsExportObj(obj1);
      if (props.excalated !== undefined) {
        obj1.escalated = props.excalated
      }
      if (props.flagged !== undefined) {
        obj1.auto_flagged = props.flagged
      }
      if (props.flaggedDate !== undefined) {
        obj1.flagged_date = props.flaggedDate
      }
      setLoader(true);
      var qurey = new URLSearchParams(obj1).toString();
      return WebService.postAPI({
        action: `whatsapp-message?` + qurey,
        body: props.filterObject.percentage === undefined ? { ...props.filterObject, "percentage": 100, "oldReviewId": oldReviewId } : { ...props.filterObject, "oldReviewId": oldReviewId } ?? {},
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          setReviewId(res.list[0].review.uuid);
          getMessageList(1, res.list[0].review.uuid);

        })
        .catch((error: any) => {
          setLoader(false);
          return error;
        });
    }
  };

  const getMessageList = (pg: any, reviewId?: any, isRefresh?: any) => {
    if (isFatchCallRunning) {
      return false;
    }
    const obj1: any = {
      page: pg,
      sortBy: "message_datetime",
      orderBy: orderBy,
      smsType: smsType,
      keyword: props.keyWord ?? "",
    };
    setSmsExportObj(obj1);
    if (props.excalated !== undefined) {
      obj1.escalated = props.excalated
    }
    if (props.flagged !== undefined) {
      obj1.auto_flagged = props.flagged
    }
    if (props.flaggedDate !== undefined) {
      obj1.flagged_date = props.flaggedDate
    }
    setLoader(true);
    isFatchCallRunning = true;
    var qurey = new URLSearchParams(obj1).toString();
    return WebService.postAPI({
      action: `whatsapp-message?` + qurey,
      body: (reviewId !== undefined && !props.excalated && !props.isReviewAll) ? { "reviewId": reviewId } : props.filterObject ?? {},
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedList = res.list.map((post: any) => {
            let smsTypeClass = "";
            if (post.senderType === "INBOUND") {
              smsTypeClass = "status-badge active font-18 font-medium";
            } else {
              smsTypeClass = "status-badge active-2";
            }
            let reviewClass = "";
            if (post.markedAsFlag) {
              reviewClass = "flag";
            }
            if (post.markedAsView) {
              reviewClass = reviewClass + " " + "mardk-as-viewed";
            }
            if (post.escalateAndSend) {
              reviewClass = reviewClass + " " + "escalate-send";
            }

            return {
              ...post,
              check: false,
              reviewClass: reviewClass,
              smsTypeClass: smsTypeClass,
              bulkReview: false,
            };
          });
          setSMSList(updatedList);
        }
        else {
          setSMSList(() => []);
        }

        setLoader(false);
        setTotalCount(res.count);
        setReviewedCount(res.reviewedCount);
        setAllSelect(false);
        setallSelectForBulk(false);
        props.totalCount(res.count);
        props.reviewedCount(res.reviewedCount);
        props.smsTotalCount(res.count);
        isFatchCallRunning = false;
      })
      .catch((error: any) => {
        setLoader(false);
        isFatchCallRunning = false;
        return error;
      });
  };

  useEffect(() => {
    if (!props.isActive) {
      setIsActive(props.isActive);
    }
  }, [props.isActive]);

  const handleCheckboxChange = (clickedPost: any) => {
    if (clickedPost.check === false) {
      var arr = [...deleteExclatedPostList];
      arr.push(clickedPost.id);
      setDeleteExcalatedPost(arr);

      if (deleteExclatedPostList.length === smsList.length) {
        setAllSelect(true);
      }
      props.bulkExport(arr);
    } else {
      var arr = deleteExclatedPostList.filter((id) => id !== clickedPost.id);
      setDeleteExcalatedPost(arr);
      setAllSelect(false);
      props.bulkExport(arr);
    }
    setSMSList((prevList) =>
      prevList.map((post) =>
        post.id === clickedPost.id ? { ...post, check: !post.check } : post
      )
    );
  };


  const handleAllSelect = (checkeStatus: any) => {
    const updatedList = smsList.map((post: any) => ({
      ...post,
      check: checkeStatus,
    }));

    const selectedIds = updatedList
      .filter((post: any) => post.check)
      .map((post: any) => post.id);

    setAllSelect(checkeStatus);
    setSMSList(updatedList);
    setDeleteExcalatedPost(selectedIds);
    props.bulkExport(selectedIds);
  };
  const handleDeleteEscalated = () => {
    if (deleteExclatedPostList.length === 0) {
      toast.error("Please select at least one post")
      return false;
    }
    WebService.addLoader("remove3");
    var obj = {
      ids: deleteExclatedPostList,
      socialType: "WHATSAPP",
    };
    return WebService.postAPI({
      action: `whatsapp/escalated`,
      body: obj,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        toast.success(res.message);
        getMessageList(page);
        WebService.removeLoader("remove3");
      })
      .catch((error: any) => {
        WebService.removeLoader("remove3");
        return error;
      });
  };

  const handleCallback = (data: any, post: any) => {
    if (data) {
      setIsConversation(data);
    }
    setSmsConversationData(post);
  };
  const handleBulkReviewCheckboxChange = (clickedPost: any) => {

    if (clickedPost.bulkReview === false) {
      var arr = [...bulkReviewList];
      arr.push(clickedPost.id);
      setBulkReviewList(arr);

      if (bulkReviewList.length === smsList.length) {
        setallSelectForBulk(true);
      }
      if (bulkReviewList.length === smsList.length) {
        setAllSelect(true);
      }
    } else {
      var arr = bulkReviewList.filter((id) => id !== clickedPost.id);
      setBulkReviewList(arr);
      setallSelectForBulk(false);
    }

    props.sendBulkReviewList(arr);
    setSMSList((prevList) =>
      prevList.map((post) =>
        post.id === clickedPost.id
          ? { ...post, bulkReview: !post.bulkReview }
          : post
      )
    );
  };

  const handleAllSelectForBulk = (checkeStatus: any) => {
    const updatedList = smsList.map((post: any) => ({
      ...post,
      bulkReview: checkeStatus,
    }));

    const selectedIds = updatedList
      .filter((post: any) => post.bulkReview)
      .map((post: any) => post.id);

    setallSelectForBulk(checkeStatus);
    setSMSList(updatedList);
    setBulkReviewList(selectedIds);
    props.sendBulkReviewList(selectedIds);

  };

  const handleExport = () => {
    WebService.addLoader("export-btn-sms");
    var obj = { ...props.filterObject, 'type': props.selectedTab }
    return WebService.postAPI({
      action: `download/manager?type=${props.selectedTab}&keyword=${props.keyWord}`,
      body: { ...props.filterObject, reviewId: reviewId },
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        toast.success(res.message);
        WebService.removeLoader("export-btn-sms");
      })
      .catch((error: any) => {
        WebService.removeLoader("export-btn-sms");
        return error;
      });
  };

  useEffect(() => {
    getMyContext();
  }, [keyword1, myContactpage]);

  const getMyContext = () => {
    setContactLoader(true);
    return WebService.getAPI({
      action: `imessage/user-contacts?page=${myContactpage}&keyword=${keyword1}`,
      body: null,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedList = res.list.map((post: any) => {
            return {
              ...post,
              check: false,
              myContextCheck: false,
            };
          });
          setMyContextList(updatedList);
        } else {
          setMyContextList([]);
        }
        setMyContactCount(res.count);
        setContactLoader(false);

      })
      .catch((error: any) => {
        setContactLoader(false);
        return error;
      });
  };

  const handleBulkContactDelete = () => {
    WebService.addLoader("contact-delete-btn");
    var body =
    {
      "idList": bulkContactDelete,
      "userId": userIdDelete

    }
    return WebService.postAPI({
      action: `imessage/delete-contacts`,
      body: body,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        setUserIdDelete(undefined);
        toast.success(res.message);
        WebService.removeLoader("contact-delete-btn");
        setShowContactDelete(false);
        setIsShowDeleteBtn(false);
        getMyContext();
      })
      .catch((error: any) => {
        WebService.removeLoader("contact-delete-btn");
        return error;
      });

  }

  const userPromiseOption = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      var body = {
        "socialType": "IMESSAGE",
        "accountType": "",
        "accountStatus": "",
        "myConnector": false,
        "idList": []
      }
      return WebService.postAPI({
        action:
          `user-integration/connectors?page=1&keyword=` + inputValue,
        body: body,
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res.list.length > 0) {
            var updatedOptions = res.list
              .filter((user: any) => user.accountStatus == "ACTIVE")
              .map((user: any, index: any) => ({
                id: user.userId,
                value: index,
                label: user.firstName + " " + user.lasttName + " " + "(" + user.refId + ")",
              }));
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onUserSelection = (selectedOption: any) => {
    setSelectContactErr("");
    setSelectedContact(selectedOption);
  };

  const changePhone = (value: any) => {
    setValue1("refId", value);
    watchVariable1.refId = value;
    setContactFlag(false);
  };

  const handleError1 = () => {
    if ((selectedContact.value == "" || selectedContact.value == undefined) &&
      (watchVariable1.refId === "" || watchVariable1.refId === undefined)
    ) {
      setContactFlag(true);
      setSelectContactErr("Please select iMessage user");
      return false;
    }
  }

  const handleAddContact = () => {
    if (role !== "BASIC_USER" && (selectedContact.id == "" || selectedContact.id == undefined)) {
      setSelectContactErr("Please select iMessage user");
      return false;
    }
    if (watchVariable1.refId === "" || watchVariable1.refId === undefined) {
      setContactFlag(true);
      return false;
    }
    if (watchVariable1.refId.split("").length == 1) {
      setContactFlag(true);
      return false;
    }
    WebService.addLoader("add-contact-save-btn");
    var body =
    {
      "number": watchVariable1.refId,
      "firstName": watchVariable1.firstName,
      "lastName": watchVariable1.lastName,
      "userId": role !== "BASIC_USER" ? selectedContact.id : parseInt(userId ?? "")
    }
    return WebService.postAPI({
      action: `imessage/add-contacts`,
      body: body,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        setSelectedContact({
          id: "",
          value: "",
          label: "Select iMessage user",
        });
        toast.success(res.message);
        WebService.removeLoader("add-contact-save-btn");
        setShowAddContactPopup(false);
        getMyContext();
        reset1();
      })
      .catch((error: any) => {
        WebService.removeLoader("add-contact-save-btn");
        return error;
      });

  }


  return (
    <>
      {
        (smsList.length === 0 && props.excalated) ||
          (smsList.length === 0 && props.reviewAllFlag)
          ? (
            props.filterObject !== null && props.filterFlag ? (
              <div className="collapse-page">
                <div className="data-container">
                  <div className="table-wrap position-relative">
                    <div className="collapse-page">
                      <div
                        className={`table-card overflow-hidden w-100 ${props.isActive ? "postTableWidth" : ""
                          }`}
                      >
                        <div className="w-100">

                          <Row className=" align-items-center mb-3">
                            {/* <Col className="d-flex gap-3">
                              <Form.Check
                                type="checkbox"
                                id="smsType-inbound-check"
                                label="Outgoing"
                                onChange={() => {
                                  setSmsType((prevType: any) =>
                                    prevType === "INBOUND" || prevType === ""
                                      ? "OUTBOUND"
                                      : ""
                                  );
                                }}
                                checked={smsType === "OUTBOUND"}
                              />
                              <Form.Check
                                type="checkbox"
                                id="smsType-outbound-check"
                                label="Incoming"
                                onChange={() => {
                                  setSmsType((prevType: any) =>
                                    prevType === "OUTBOUND" || prevType === ""
                                      ? "INBOUND"
                                      : ""
                                  );
                                }}
                                checked={smsType === "INBOUND"}
                              />
                            </Col> */}
                            <Col className="text-end">
                              {!props.reviewAllFlag ? (
                                props.excalated ? (
                                  <Button
                                    id="remove3"
                                    className="btn-brand-light me-3"
                                    onClick={() => {
                                      handleDeleteEscalated();
                                    }}
                                  >
                                    Remove from Escalation
                                  </Button>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {props.excalated || props.reviewAllFlag ? (
                                <Button
                                  className="btn-brand-light text-nowrap"
                                  onClick={handleShowFilterBlade}
                                >
                                  <BsFunnel size={18} /> Filter{" "}
                                </Button>
                              ) : (
                                ""
                              )}
                              <Button
                                className="btn-brand-light me-3 ms-3"
                                onClick={() => {
                                  setPage(1);
                                  setOrderBy("DESC");
                                  setSortBy("dateTime");
                                  setRefresh(true);
                                  getMessageList(page, reviewId, true);
                                  toggleViewSidebar(false);
                                }}
                              >
                                <RxReload size={16} />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <Card className="table-container">
                          <div className="no-data-flound bg-white py-5 rounded-4">
                            <div className="text-center">
                              <img src={NoDataFound} width={110} className="mb-3" />
                              <h3 className="text-secondary font-12">
                                {
                                  excalated ? "No escalated data found for Whatsapp" : "No data found for Whatsapp"
                                }
                              </h3>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            <div className="collapse-page">
              <div className={`table-card overflow-hidden w-100 ${props.isActive ? "postTableWidth" : ""}`} >
                <div className="w-100">
                  <div className="w-100">
                    <Row className={JSON.stringify(props.filterObject) !== '{}' ? "align-items-center border-bottom mb-3" : "align-items-center"}>
                      <Col className="d-flex gap-3 justify-content-between align-items-center pb-3">
                        <h3 className="table-heading text-nowrap">WhatsApp  {`(${HelperService.abbrNum(totalCount, 2)})`}</h3>
                        {Object.keys(props.filterObject).length > 1 && !props.excalated && props.filterObject?.reviewId === undefined ?
                          <>
                            <div className="d-flex gap-1 flex-wrap align-items-center w-100">
                              {(props.filterObject?.sender && props.filterObject?.sender?.length > 0 ?
                                props.filterObject.sender.map((sender: any) => {
                                  return (
                                    <>
                                      <Badge bg="secondary">
                                        Sender: {sender}
                                        <CloseButton
                                          variant="white"
                                          style={{ fontSize: "9px" }}
                                          onClick={() => props.clearFilterCallback("Sender", sender)}
                                        />
                                      </Badge>
                                    </>)
                                })
                                : "")
                              }

                              {(props.filterObject?.recipient && props.filterObject?.recipient?.length > 0 ?
                                props.filterObject.recipient.map((receiver: any) => {
                                  return (<>
                                    <Badge
                                      bg="secondary">
                                      Receiver:{receiver}
                                      <CloseButton
                                        variant="white"
                                        style={{ fontSize: "9px" }}
                                        onClick={() => props.clearFilterCallback("Recipient", receiver)}
                                      />
                                    </Badge>
                                  </>)
                                })
                                : "")
                              }

                              {props.filterObject?.startDate && props.filterObject?.endDate
                                ?
                                <Badge
                                  bg="secondary">
                                  Date range: {HelperService.getFormatedDateForDetail(props.filterObject?.startDate, "MM-DD-YYYY")} - {HelperService.getFormatedDateForDetail(props.filterObject?.endDate, "MM-DD-YYYY")}
                                </Badge>
                                : ""}

                              {props.filterObject?.keywords
                                ? <Badge bg="secondary">Keyword: {props.filterObject?.keywords}
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("Keyword", "")}
                                  /></Badge>
                                : ""}

                              {props.filterObject?.percentage
                                ? <Badge bg="secondary">Percentage: {props.filterObject?.percentage}
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("Percentage", "")}
                                  /></Badge>
                                : ""}

                              {props.filterObject?.markedAsFlag
                                ? <Badge bg="secondary">Show Flagged Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("Flagged", "")}
                                  /></Badge>
                                : ""}
                              {props.filterObject?.markedAsView
                                ? <Badge bg="secondary">Show Reviewed Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("Reviewed", "")}
                                  /></Badge>
                                : ""}
                              {props.filterObject?.escalateAndSend
                                ? <Badge bg="secondary">Show Escalated Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("Escalated", "")}
                                  /></Badge>
                                : ""}
                              {props.filterObject?.flaggedNotReviewed
                                ? <Badge bg="secondary">Show Flagged But Not Reviewed Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("NotReviewed", "")}
                                  /></Badge>
                                : ""}
                              {props.filterObject?.notReviewed
                                ? <Badge bg="secondary">Show Not Yet Reviewed Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("NotYetReviewed", "")}
                                  /></Badge>
                                : ""}
                            </div>
                            {JSON.stringify(props.filterObject) != '{}' ?
                              <div className="text-center d-flex gap-2 align-items-lg-center">
                                {
                                  smsList?.length > 0 ?
                                    <button
                                      className="btn btn-brand-light w-100 btn btn-primary text-nowrap mb-1"
                                      onClick={() => setShowReviewNamePopup(true)}
                                    >
                                      Save Review
                                    </button>
                                    : ""
                                }

                                <a
                                  className="text-brand font-14 text-nowrap"
                                  href="javascript:void(0)"
                                  onClick={() => props.clearFilterCallback("ALL", {})} >
                                  Clear filter
                                </a>
                              </div> : ""}

                          </>
                          : ""}
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-3">
                      {/* <Col className="d-flex gap-3">
                        <Form.Check
                          type="checkbox"
                          id="smsType"
                          label="Outgoing"
                          onChange={() => {
                            setSmsType((prevType: any) =>
                              prevType === "INBOUND" || prevType === ""
                                ? "OUTBOUND"
                                : ""
                            );
                          }}
                          checked={smsType === "OUTBOUND"}
                        />
                        <Form.Check
                          type="checkbox"
                          id="smsTyp1e"
                          label="Incoming"
                          onChange={() => {
                            setSmsType((prevType: any) =>
                              prevType === "OUTBOUND" || prevType === ""
                                ? "INBOUND"
                                : ""
                            );
                          }}
                          checked={smsType === "INBOUND"}
                        />
                      </Col> */}
                      <Col className="text-end">
                        {!props.reviewAllFlag ? (
                          props.excalated ? (
                            <Button
                              id="remove3"
                              className="btn-brand-light me-3"
                              onClick={() => {
                                handleDeleteEscalated();
                                toggleViewSidebar(false);
                              }}
                            >
                              Remove from Escalation
                            </Button>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {props.excalated || props.reviewAllFlag ? (
                          <Button
                            className="btn-brand-light text-nowrap"
                            onClick={handleShowFilterBlade}
                          >
                            <BsFunnel size={18} /> Filter{" "}
                          </Button>
                        ) : (
                          ""
                        )}
                        {/* {
                          whatsappList.length > 0
                            && !props.excalated && !props.reviewAllFlag
                            && role !== "BASIC_USER" ?
                            <Button
                              id="export-btn"
                              className="btn-brand-light text-nowrap me-3"
                              onClick={handleExport}
                            >
                              <TbCloudUpload size={18} className="me-1" />
                              Export
                            </Button>
                            : ""
                        } */}
                        {!props.excalated && !props.reviewAllFlag ?
                          <Button
                            id="export-btn-sms"
                            className="btn-brand-light text-nowrap me-2"
                            onClick={() => { setShowMyContextPopup(true) }}
                          >
                            My Contacts
                          </Button>
                          : ""
                        }
                        <UserContact showMyContextPopup={showMyContextPopup} type={'WHATSAPP'} uiaId={""} userId={""} isMyContact={false} changeStatus={() => { setShowMyContextPopup(false) }}></UserContact>

                        <Button
                          className="btn-brand-light me-3 ms-3"
                          onClick={() => {
                            setPage(1);
                            setOrderBy("DESC");
                            setSortBy("dateTime");
                            setRefresh(true);
                            getMessageList(page, reviewId, true);
                            toggleViewSidebar(false);
                          }}
                        >
                          <RxReload size={16} />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Card className="table-container">
                    <Table className="table-style">
                      <thead>
                        <tr>
                          <th>
                            <div className={"d-flex"}>
                              {complainceUserLogAccess && role !== "BASIC_USER" ? (
                                props.excalated || props.reviewAllFlag ? (
                                  <Form.Check
                                    type="checkbox"
                                    id="selectAll"
                                    checked={allSelect}
                                    onClick={() => {
                                      handleAllSelect(!allSelect);
                                    }}
                                    label=""
                                  />
                                ) : (
                                  <Form.Check
                                    type="checkbox"
                                    id="selectAll1"
                                    checked={allSelectForBulk}
                                    onClick={() => {
                                      handleAllSelectForBulk(!allSelectForBulk);
                                    }}
                                    label=""
                                  />
                                )
                              ) : (
                                ""
                              )}
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  setSortBy("date_time");
                                  setClickable("date_time");
                                }}
                              >
                                Date
                              </span>

                              {sortBy === "date_time" &&
                                orderBy === "DESC" &&
                                clickable === "date_time" ? (
                                <MdOutlineKeyboardArrowDown
                                  className="sort-icon cursor-pointer"
                                  size={14}
                                  onClick={() => {
                                    setOrderBy("ASC");
                                    setSortBy("date_time");
                                  }}
                                />
                              ) : clickable === "date_time" ? (
                                <MdOutlineKeyboardArrowUp
                                  className="sort-icon cursor-pointer"
                                  size={14}
                                  onClick={() => {
                                    setOrderBy("DESC");
                                    setSortBy("date_time");
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </th>
                          {
                            props.selectedTab !== "MY_REPCHAT"
                              ?
                              <th
                                className="cursor-pointer"
                                onClick={() => {
                                  setSortBy("from_name");
                                  setClickable("from_name");
                                  // setOrderBy("DESC");
                                }}
                              >
                                Sender
                                {sortBy === "from_name" &&
                                  orderBy === "DESC" &&
                                  clickable === "from_name" ? (
                                  <MdOutlineKeyboardArrowDown
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("ASC");
                                      setSortBy("from_name");
                                      setClickable("from_name");
                                    }}
                                  />
                                ) : clickable === "from_name" ? (
                                  <MdOutlineKeyboardArrowUp
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("DESC");
                                      setSortBy("from_name");
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </th>
                              : ""}
                          {
                            props.selectedTab !== "MY_REPCHAT"
                              ?
                              <th
                                className="cursor-pointer"
                                onClick={() => {
                                  setSortBy("from_no");
                                  setClickable("from_no");
                                  // setOrderBy("DESC");
                                }}
                              >
                                Number
                                {sortBy === "from_no" &&
                                  orderBy === "DESC" &&
                                  clickable === "from_no" ? (
                                  <MdOutlineKeyboardArrowDown
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("ASC");
                                      setSortBy("from_no");
                                      setClickable("from_no");
                                    }}
                                  />
                                ) : clickable === "from_no" ? (
                                  <MdOutlineKeyboardArrowUp
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("DESC");
                                      setSortBy("from_no");
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </th> : ""}
                          {
                            props.selectedTab !== "MY_REPCHAT"
                              ?
                              <th
                                className="cursor-pointer"
                                onClick={() => {
                                  setSortBy("to_name");
                                  setClickable("to_name");
                                  // setOrderBy("DESC");
                                }}
                              >
                                Receiver
                                {sortBy === "to_name" &&
                                  orderBy === "DESC" &&
                                  clickable === "to_name" ? (
                                  <MdOutlineKeyboardArrowDown
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("ASC");
                                      setSortBy("to_name");
                                      setClickable("to_name");
                                    }}
                                  />
                                ) : clickable === "to_name" ? (
                                  <MdOutlineKeyboardArrowUp
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("DESC");
                                      setSortBy("to_name");
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </th> : ""}
                          {
                            props.selectedTab !== "MY_REPCHAT"
                              ?
                              <th
                                className="cursor-pointer"
                                onClick={() => {
                                  setSortBy("to_no");
                                  setClickable("to_no");
                                  // setOrderBy("DESC");
                                }}
                              >
                                Number
                                {sortBy === "to_no" &&
                                  orderBy === "DESC" &&
                                  clickable === "to_no" ? (
                                  <MdOutlineKeyboardArrowDown
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("ASC");
                                      setSortBy("to_no");
                                    }}
                                  />
                                ) : clickable === "to_no" ? (
                                  <MdOutlineKeyboardArrowUp
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("DESC");
                                      setSortBy("to_no");
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </th>
                              : ""
                          }
                          {
                            props.selectedTab === "IMESSAGE"
                              ?
                              <th className="">
                                Group Name
                              </th>
                              : ""
                          }
                          {
                            props.selectedTab !== "MY_REPCHAT"
                              ?
                              <th
                                className="cursor-pointer"
                                onClick={() => {
                                  setSortBy("sms_type");
                                  setClickable("sms_type");
                                  // setOrderBy("DESC");
                                }}
                              >
                                Type
                                {sortBy === "sms_type" &&
                                  orderBy === "DESC" &&
                                  clickable === "sms_type" ? (
                                  <MdOutlineKeyboardArrowDown
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("ASC");
                                      setSortBy("sms_type");
                                    }}
                                  />
                                ) : clickable === "sms_type" ? (
                                  <MdOutlineKeyboardArrowUp
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                    onClick={() => {
                                      setOrderBy("DESC");
                                      setSortBy("sms_type");
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </th>
                              : ""
                          }

                          <th className="action-col cursor-pointer">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loader ? (
                          <>
                            {smsList && smsList.length > 0 ? (
                              smsList.map((sms: any) => {
                                const isRowActive =
                                  selectedPost && selectedPost.id === sms.id;
                                return (
                                  <tr
                                    className={`${sms.reviewClass} ${isRowActive ? "active-row" : ""
                                      }`}
                                  >
                                    <td className="text-nowrap">
                                      {complainceUserLogAccess && role !== "BASIC_USER" ? (
                                        props.excalated || props.reviewAllFlag ? (
                                          <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={sms.check}
                                            id={sms.id}
                                            onChange={() => {
                                              handleCheckboxChange(sms);
                                            }}
                                          />
                                        ) : (
                                          <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={sms.bulkReview}
                                            id={sms.id}
                                            onChange={() => {
                                              handleBulkReviewCheckboxChange(sms);
                                            }}
                                          />
                                        )
                                      ) : (
                                        ""
                                      )}

                                      <span className="me-1 icon-flag">
                                        {sms.markedAsFlag ? (
                                          <HiMiniFlag
                                            size={16}
                                            className=" text-danger opacity-50"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      <Link
                                        className="text-nowrap"
                                        to=""
                                        onClick={() => {
                                          setSelectedPost(
                                            JSON.parse(JSON.stringify(sms))
                                          );
                                          toggleViewSidebar(true);
                                          toggleConversationSlideBar(false);
                                        }}
                                      >
                                        {moment(sms.messageDateTime)
                                          .local()
                                          .format("MM-DD-YY hh:mm A ")}
                                      </Link>
                                    </td>
                                    <td>{sms.senderDisplayName}</td>

                                    <td>{sms.fromNumber}</td>

                                    <td>{sms.recieverName}</td>

                                    <td>{sms.toNumber}</td>

                                    {/* <td>{sms.groupName}</td> */}

                                    <td>
                                      <span className={sms.smsTypeClass}>
                                        {sms.senderType}
                                      </span>
                                    </td>

                                    <td>
                                      <div className="action-btns text-center">
                                        <Button
                                          className="action-btn btn-view "
                                          title="View"
                                          onClick={() => {
                                            setGroupName(sms.groupName)
                                            setSelectedPost(
                                              JSON.parse(JSON.stringify(sms))
                                            );
                                            toggleViewSidebar(true);
                                            setIsConversation(false);

                                          }}
                                        >
                                          <MdOutlineRemoveRedEye className="icon" />{" "}
                                          View
                                        </Button>
                                      </div>{" "}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>
                                  <div className="table-wrap position-relative w-100">
                                    <div className="no-data-found w-100">
                                      <div className="text-center" style={{ marginLeft: "110px" }}>
                                        <img
                                          src={NoDataFound}
                                          width={110}
                                          className="mb-3"
                                          alt={""}
                                        />
                                        <h3 className="text-secondary font-12">
                                          No Record Found
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <TableLoader />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {
                      !loader ?
                        <AIPagination
                          pagination={pagination}
                          totalItems={totalCount}
                          changePage={(page: number) => {
                            setPage(page);
                          }}
                          changeCurrentPage={page}
                        />
                        : ""
                    }

                  </Card>
                </div>
              </div>
              <div>
                <div
                  className={`filter-container ${isActive ? "open-filter topremove" : "postViewHeight topremove"
                    }`}
                >
                  <Card className="filter-card border topremove" >
                    <Card.Header>
                      <h3 className="card-title">Message Archive</h3>
                      <div className="d-flex gap-2">
                        <div >
                          <TooltipCustom
                            st={
                              <img src={auditLogIcon} className="" card-title="Audit Logs" onClick={() => { setShowAuditLog(true); }} style={{ height: "17px", cursor: "pointer" }} />
                            }
                            message={"Audit logs"}
                            position="top"
                            id="c_btn"
                          ></TooltipCustom>
                        </div>

                        <a
                          className="cursor-pointer close-btn"
                          onClick={() => toggleViewSidebar(false)}
                        >
                          <CgClose />
                        </a>
                      </div>
                    </Card.Header>
                    <MessageViewBlade
                      isReviewed={(bool: boolean) => {
                        if (props.isReviewed) {
                          props.isReviewed(bool);
                        }
                      }}
                      selectedTab={props.selectedTab}
                      parentCallback={handleCallback}
                      post={selectedPost}
                      automark={automark}
                      sendSMSData={(obj: any) => {
                        if (props.excalated) {
                          getMessageList(page);
                        } else {
                          if (obj.changedPost != null) {
                            const myNextList = [...smsList];
                            const post1: any = myNextList.find(
                              (a) => a.id === obj.changedPost.id
                            );
                            post1.markedAsFlag = obj.changedPost.markedAsFlag;
                            post1.markedAsView = obj.changedPost.markedAsView;
                            post1.escalateAndSend = obj.changedPost.escalateAndSend;
                            post1.notes = obj.changedPost.notes;

                            if (post1.reviewClass !== null) {
                              post1.reviewClass = obj.changedPost.reviewClass;
                            }
                            setSMSList(myNextList);
                            setSelectedPost(obj.changedPost);
                          }
                        }
                      }}
                    />
                  </Card>
                </div>
                {isConversation ? (
                  <div
                    className={`filter-container topremove2 ${isActive ? "open-filter " : "postViewHeight"
                      }`}
                  >
                    <Card className="filter-card border">
                      <Card.Header>
                        <h3 className="card-title">{groupName !== undefined ? `${groupName} Conversation` : `Conversation`}</h3>
                        <a
                          className="cursor-pointer close-btn"
                          onClick={() => toggleConversationSlideBar(false)}
                        >
                          <CgClose />
                        </a>
                      </Card.Header>
                      <MessageConversationBlade
                        smsConversationData={smsConversationData}
                        selectedTab={props.selectedTab}
                      />
                    </Card>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div >
          )}

      <Modal show={showContactDelete} onHide={() => setShowContactDelete(false)} centered>
        <Modal.Header
          closeButton
          onClick={() => setShowContactDelete(false)}
          className=" border-0 pb-0"
        ></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="text-danger">
              <PiSealWarning size={70} />
            </h2>
            <h4 className="font-18">
              Are you sure you want to delete?
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer className=" justify-content-center border-0 pt-0">
          <Button className="btn-brand-light" onClick={() => { setUserIdDelete(undefined); setShowContactDelete(false) }} >
            No
          </Button>
          <Button className="btn-brand-1" id="contact-delete-btn" onClick={() => handleBulkContactDelete()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Offcanvas
        show={showAddContactPopup}
        onHide={() => { setShowAddContactPopup(false) }}
        placement="end"
        className="righSide-modal size-md"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Add Contact
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Form
          className="w-100 form-style"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit1(handleAddContact, handleError1)}
        >
          <OffcanvasBody>
            {
              role !== "BASIC_USER" ?
                < Col lg={12} className="mb-3">
                  <AsyncSelect
                    {...register1("userId")}
                    cacheOptions
                    defaultOptions
                    onChange={onUserSelection}
                    loadOptions={userPromiseOption}
                    defaultValue={selectedContact}
                  />
                  <span className="text-danger font-12">{selectContactErr}</span>
                </Col>
                : ""
            }

            <Col lg={12} className="mb-3">
              <PhoneInput
                country={"us"}
                // value={mobile}
                onChange={changePhone}
                specialLabel=""
                placeholder="Mobile number"
              />
              {
                contactFlag
                  ? (
                    <Label
                      title={"Please enter contact number"}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  ) : (
                    ""
                  )}
            </Col>
            <Row lg={12}>
              <Col lg={6}>
                <FloatingLabel
                  controlId="firstName"
                  label="First Name"
                  className="mb-2"
                >
                  <input
                    {...register1("firstName", {
                      required: "Please enter first name",
                    })}
                    type="text"
                    className="form-control ps-0 "
                    placeholder="First Name"
                    onChange={(e) => {
                      setValue1("firstName", e.target.value);
                      watchVariable1.firstName = e.target.value
                    }}
                  />
                  {errors1.firstName && (
                    <Label
                      title={errors1.firstName.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </FloatingLabel>
              </Col>
              <Col lg={6}>
                <FloatingLabel
                  controlId="lastName"
                  label="Last Name"
                  className="mb-2"
                >
                  <input
                    {...register1("lastName", {
                      required: "Please enter last name",
                    })}
                    type="text"
                    className="form-control ps-0 "
                    placeholder="Last Name"
                    onChange={(e) => {
                      setValue1("lastName", e.target.value);
                      watchVariable1.lastName = e.target.value
                    }}
                  />
                  {errors1.lastName && (
                    <Label
                      title={errors1.lastName.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </FloatingLabel>
              </Col>
            </Row>

            <div className="mt=2 ">
              <Button
                type="submit"
                className="btn btn-brand-1 w-100"
                id="add-contact-save-btn"
              >
                Save
              </Button>
            </div>
          </OffcanvasBody>
        </Form>
      </Offcanvas >

      <Offcanvas
        show={showReviewNamePopup}
        onHide={() => setShowReviewNamePopup(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Review Name</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-0 py-3">
          <ReviewNamePopup socialType={"WHATSAPP"} reviewId={reviewId} filterObject={props.filterObject} list={smsList} parentcallback={() => setShowReviewNamePopup(false)} />
        </Offcanvas.Body>
      </Offcanvas>

      {showAuditLog ? <AuditLog post_id={selectedPost.id} show={showAuditLog} isShow={(e: any) => { setShowAuditLog(e) }} type={"WHATSAPP"} /> : ""}

    </>
  );
};

export default MessageTab;
