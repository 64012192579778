import { Form, Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AIDatePicker from "../../Common/AIDatePicker/AIDatePicker";
import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import WebService from "../../../Services/WebService";
import { Label } from "../../Common/Label/Label";
import { use } from "i18next";
import HelperService from "../../../Services/HelperService";
import { useSelector } from "react-redux";
interface propsData {
  closeBlade: any;
}

const FlaggingReportFilterBlade = (props: propsData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  var navigate = useNavigate();
  const [reviewFlag, setReviewFlag] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedFolder, setSelectedFolders] = useState<any[]>([]);
  const [folderFlag, setFolderflag] = useState(false);
  const [reviewerFlag, setReviewrFlag] = useState(false);
  const [selectedReviewers, setSelectedReviewerList] = useState<any[]>([]);
  const { register, handleSubmit, setValue, watch } = useForm();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  var watchVariable = watch();
  useEffect(() => {
    watchVariable.startDate = startDate;
    setValue("startDate", startDate);
    watchVariable.endDate = endDate;
    setValue("endDate", endDate);
  }, []);

  const handleApply = () => {
    setValue("accessRights", "ALL")
    watchVariable.accessRights = "ALL"
    setValue("isFlagged", true)
    watchVariable.isFlagged = true
    setValue("isViewed", true)
    watchVariable.isViewed = true
    setValue("isEscalated", true)
    watchVariable.isEscalated = true
    if (selectedFolder.length === 0) {
      setFolderflag(true);
      return;
    } else {
      var arr: any = [];
      selectedFolder.forEach((folder: any) => {
        if (folder.label == "Ring central") {
          folder.label = "RING_CENTRAL"
        }
        if (folder.label == "Zoom sms") {
          folder.label = "ZOOM_SMS"
        }
        if (folder.label == "My repchat") {
          folder.label = "MY_REPCHAT"
        }
        arr.push(folder.label.toUpperCase());
      });
      watchVariable.folders = arr;
      setFolderflag(false);
    }
    if (selectedReviewers.length === 0) {
      setReviewrFlag(true);
      return;
    } else {
      var userArr: any = [];
      var grpArr: any = [];


      selectedReviewers.forEach((reviewer: any) => {
        var obj = { id: reviewer.value };
        if (reviewer.applyType === "USER") {
          userArr.push(obj);
        } else {
          grpArr.push(obj);
        }
      });

      let obj = {
        userList: userArr,
        groupList: grpArr,
      };
      watchVariable.reviewers = obj;
      setReviewrFlag(false);
    }

    navigate("reports/flagging-report", {
      state: { filterObject: watchVariable },
    });
    props.closeBlade(false);
  };

  const handleUserCheckboxChange = (userId: any) => {
    setSelectedUsers((prevUsers: any) => {
      if (prevUsers.includes(userId)) {
        return prevUsers.filter((id: any) => id !== userId);
      } else {
        return [...prevUsers, userId];
      }
    });
  };


  const handleGroupCheckboxChange = (groupId: any) => {
    setSelectedGroups((prevGroups: any) => {
      if (prevGroups.includes(groupId)) {
        return prevGroups.filter((id: any) => id !== groupId);
      } else {
        return [...prevGroups, groupId];
      }
    });
  };

  const onDateChange = (date: any, type: any) => {
    if (type === "startDate") {
      setStartDate(date);
      watchVariable.startDate = date;
      setValue("startDate", date);
    } else if (type === "endDate") {
      setEndDate(date);
      watchVariable.endDate = date;
      setValue("endDate", date);
    }
  };

  const handleError = (error: any) => {
    if (selectedFolder.length === 0) {
      setFolderflag(true);
      return;
    }
    if (selectedReviewers.length === 0) {
      setReviewFlag(true);
      return;
    }

  };

  const onReviwerSelection = (selectedOptions: any) => {
    var arr: any = [];
    if (selectedOptions.length > 0) {
      selectedOptions.forEach((element: any, index: any) => {
        if (index === selectedOptions?.length - 1) {
          var obj = {
            "value": element.value,
            "label": element.label?.props?.children[1],
            "applyType": element.applyType
          }
          arr.push(obj);
        } else {
          arr.push(element);
        }

      });
    }
    setSelectedReviewerList(arr);
    if ((selectedReviewers.length = 0)) {
      setReviewrFlag(true);
    } else {
      setReviewrFlag(false);
    }
  };

  const onFolderSelection = (selectedOptions: any) => {
    var arr: any = [];
    if (selectedOptions.length > 0) {
      selectedOptions.forEach((element: any, index: any) => {
        if (index === selectedOptions?.length - 1) {
          var obj = {
            "value": element.value,
            "label": element.label?.props?.children[1],
          }
          arr.push(obj);
        } else {
          arr.push(element);
        }

      });
    }
    setSelectedFolders(arr);
    if (selectedFolder.length === 0) {
      setFolderflag(true);
    } else {
      setFolderflag(false);
    }
  };

  const folderPromiseOption = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      var body =
        { "idList": [], "socialType": [] };
      return WebService.postAPI({
        action: `user-integration/all-connectors?kayword=${inputValue}`,
        body: body,
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res.length > 0) {
            var updatedOptions = res.map((integration: any, index: any) => ({
              value: index,
              label: integration.type === "ZOOM_SMS" ? "Zoom SMS" : integration.type,

            }));

            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const checkSelectedChannels = (channel: any) => {
    return selectedFolder.find((item: any) => item.label === channel);
  };

  useEffect(() => {
  }, [selectedReviewers]);

  const handleSelectAll = (event: any, options: any, type: any) => {
    var arr: any[] = []
    options.map((opt: any, index: any) => {
      var obj = {
        "value": opt.value,
        "label": opt.label?.props?.children[1],
        "applyType": opt.applyType
      }
      arr.push(obj);
    })
    const updatedOptions = arr.map((option: any) => {
      return {
        ...option,
        isSelected: event,
      };
    });
    setSelectedReviewerList((prevSelectedReviewers) => [
      ...updatedOptions,
      ...prevSelectedReviewers.filter((option) => option.applyType !== "GROUP" || option.applyType !== "USER"),
    ]);
  };

  const handleSelectAllFolders = (event: any, options: any) => {
    setSelectedFolders([]);
    var arr: any[] = []
    options.map((opt: any, index: any) => {
      var obj = {
        "value": opt.value,
        "label": opt.label?.props?.children[1],
        "applyType": opt.applyType
      }
      arr.push(obj);
    })
    const updatedOptions = arr.map((option: any) => {
      return {
        ...option,
        isSelected: event,
      };
    });
    setSelectedFolders((prevSelectedReviewers) => [
      ...updatedOptions,
      ...prevSelectedReviewers.filter((option) => option.applyType !== "GROUP" || option.applyType !== "USER"),
    ]);
  };

  const fetchFolders = async (inputValue: any) => {
    var body =
      { "idList": [], "socialType": [] };
    try {
      var userResponse: any = [];
      userResponse = await WebService.postAPI({
        action: `user-integration/all-connectors?keyword=${inputValue}`,
        body: body,
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      });
      return userResponse?.map((user: any, index: any) => ({
        value: index,
        label:
          (
            <label onChange={() => handleUserCheckboxChange(user.type)}>
              <input type="checkbox" className="me-1" />
              {/* {HelperService.removeUnderScore(HelperService.titleCase(user.type))} */}
              {user.type === "SMS" ? "SMS" :
                user.type === "ZOOM_SMS" ? "Zoom SMS" :
                  user.type === "ZOOM_CHAT" ? "Zoom Chat" :
                    user.type === "IMESSAGE" ? "iMessage" :
                      user.type === "TEAMS" ? "Teams Chat" :
                        user.type === "MY_REPCHAT" ? "MyRepChat" :
                          HelperService.titleCase(user.type)}
            </label>
          ),
      }));
    } catch (error) {
      console.error("Error fetching folders:", error);
      return [];
    }
  };

  const fetchUsers = async (inputValue: any) => {
    try {
      var userResponse: any = [];
      userResponse = await WebService.getAPI({
        action: `client/users?keyword=${inputValue}`,
        body: { query: inputValue },
        access_token: access_token,
        t_id: t_id
      });
      return userResponse?.map((user: any) => ({
        value: user.id,
        label:
          (
            <label onChange={() => handleUserCheckboxChange(user.id)}>
              <input type="checkbox" className="me-1" />
              {user.first_name + " " + user.last_name}
            </label>
          ),
        applyType: "USER",
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  };
  const fetchGroups = async (inputValue: any) => {
    try {
      var groupResponse: any = [];
      groupResponse = await WebService.getAPI({
        action: "groups",
        body: { query: inputValue },
        access_token: access_token,
        t_id: t_id
      });
      return groupResponse?.map((group: any) => ({
        value: group.id,
        label: (
          <label onChange={() => handleGroupCheckboxChange(group.id)}>
            <input type="checkbox" className="me-1" />
            {group.group_name}
          </label>
        ),
        applyType: "GROUP",
      }));
    } catch (error) {
      console.error("Error fetching groups:", error);
      return [];
    }
  };
  const ReviewrsPromiseOption = async (inputValue: any) => {
    try {
      const [userOptions, groupOptions] = await Promise.all([
        fetchUsers(inputValue),
        fetchGroups(inputValue),
      ]);
      const applyTo = [];
      if (userOptions.length > 0) {
        applyTo.push({
          label: (
            <label className="cursor-pointer" onClick={(e: any) =>
              handleSelectAll(true, userOptions, "USER")
            } >
              Select All Users
            </label >
          ),
          options: userOptions,
        });
      }

      if (groupOptions.length > 0) {
        applyTo.push({
          label: (
            <label className="cursor-pointer" onClick={(e) =>
              handleSelectAll(true, groupOptions, "GROUP")
            }>
              Select All Groups
            </label>
          ),
          options: groupOptions,
        });
      }

      return applyTo;
    } catch (error) {
      console.error("Error loading options:", error);
      return [];
    }
  };

  const ReviewrsFoldersOption = async (inputValue: any) => {
    try {
      const [folderOptions] = await Promise.all([
        fetchFolders(inputValue),
      ]);
      const applyTo = [];
      if (folderOptions.length > 0) {
        applyTo.push({
          label: (
            <label className="cursor-pointer" onClick={(e) => handleSelectAllFolders(true, folderOptions)}>
              Select All Connectors
            </label>
          ),
          options: folderOptions,
        });
      }

      return applyTo;
    } catch (error) {
      console.error("Error loading options:", error);
      return [];
    }
  };
  return (
    <Form
      className="w-100 form-style"
      name="Verify"
      id="Verify"
      onSubmit={handleSubmit(handleApply, handleError)}
    >
      <div className="px-3 mt-3">
        <div className="d-flex gap-3 mb-3">
          <Row>
            <Col>
              <AIDatePicker
                date={startDate}
                selectDate={(date: any) => onDateChange(date, "startDate")}
                maxDate={new Date()}
              />
            </Col>
            <Col>
              <AIDatePicker
                date={endDate}
                selectDate={(date: any) => onDateChange(date, "endDate")}
                maxDate={new Date()}
              />
            </Col>
          </Row>



        </div>
        <hr className=" border-secondary-subtle" />
        <h4 className="font-18 font-medium">Connectors</h4>
        <div className=" ">
          {" "}
          <AsyncSelect
            {...register("folders")}
            cacheOptions
            defaultOptions
            isMulti
            onChange={onFolderSelection}
            // loadOptions={folderPromiseOption}
            loadOptions={ReviewrsFoldersOption}
            value={selectedFolder}
          />

          {selectedFolder.length === 0 && folderFlag ? (
            <Label title="Please select at least one folder" modeError={true} />
          ) : (
            ""
          )}
        </div>

        {checkSelectedChannels("FACEBOOK") ||
          checkSelectedChannels("EMAIL") ||
          checkSelectedChannels("ZOOM") ||
          checkSelectedChannels("TEAMS") ? (
          <>
            <hr className=" border-secondary-subtle" />
            <h4 className="font-18 font-medium">Access Rights</h4>
            <Form.Select
              value={watchVariable.gender}
              {...register("accessRights")}
              onChange={(e: any) => {
                setValue("accessRights", e.target.value);
              }}
            >
              <option value="ALL">All</option>
              <option value="INCOMING">Incoming</option>
              <option value="OUTGOING">Outgoing</option>
            </Form.Select>
            {/* <hr className=" border-secondary-subtle" /> */}
          </>
        ) : (
          ""
        )}

        <div className="mb-2">
          {reviewFlag ? (
            <Label
              title="Please select review status"
              modeError={true}
              showStar={true}
              type=""
            />
          ) : (
            ""
          )}
        </div>

        <hr className=" border-secondary-subtle" />
        <h4 className="font-18 mb-3">Include Reviewers</h4>
        <div className="mb-3 position-relative" style={{ maxWidth: "600px" }}>
          <AsyncSelect
            {...register("reviewers")}
            cacheOptions
            isMulti
            defaultOptions
            onChange={onReviwerSelection}
            loadOptions={ReviewrsPromiseOption}
            value={selectedReviewers}
            placeholder="Select reviewers"
          />
          {selectedReviewers.length === 0 && reviewerFlag ? (
            <Label
              title="Please select atleast one reviewer"
              modeError={true}
            />
          ) : (
            ""
          )}
        </div>

        <Button className="btn btn-brand-1 w-100 mt-3" type="submit" id="apply">
          Generate Report
        </Button>
      </div>
    </Form>
  );
};
export default FlaggingReportFilterBlade;





