import { Button, Card, Col, Dropdown, FloatingLabel, Form, InputGroup, Modal, Nav, Offcanvas, OffcanvasBody, Row, Tab, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import NoDataFound from "../../assets/images/empty_item.svg";
import TableLoader from "../Common/TableLoader/TableLoader";
import AIPagination from "../Common/Pagination/AIPagination";
import { toast } from "react-toastify";
import "./sms-review.scss";
import { PiSealWarning, PiTrash, PiUploadSimple } from "react-icons/pi";
import { IoSearchOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Label } from "../Common/Label/Label";
import AsyncSelect from "react-select/async";
import PhoneInput from "react-phone-input-2";
import { TbCloudDownload } from "react-icons/tb";
import { useSelector } from "react-redux";
interface propsData {
  showMyContextPopup: any
  changeStatus: any
  uiaId?: any
  userId?: any
  isMyContact?: any
  type?: any
}
const UserContact = (props: propsData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const role = useSelector((state: any) => state.uR.role);
  const userId = useSelector((state: any) => state.uI.id);
  const [pagination, setPagination] = useState(100);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [allSelectForBulk, setallSelectForBulk] = useState(false);
  const [allSelectForMyContext, setallSelectForMyContext] = useState(false);
  const [showMyContextPopup, setShowMyContextPopup] = useState<any>(false);
  const [myContextList, setMyContextList] = useState<any>([]);
  const [myContactCount, setMyContactCount] = useState<any>(0);
  const [bulkContactDelete, setBulkContactDelete] = useState<any>([]);
  const [showContactDelete, setShowContactDelete] = useState<any>(false);
  const [keyword1, setKeyword1] = useState<any>("");
  const [isShowDeleteBtn, setIsShowDeleteBtn] = useState<any>(false);
  const [allSelectMyContext, setAllSelectMyContext] = useState(false);
  const [showImsgImportPopup, setShowImsgImportPopup] = useState<any>(false);
  const [myContextCheckList, setMyContextCheckList] = useState<any[]>([]);
  const [contactLoader, setContactLoader] = useState<any>(false);
  const [showAddContactPopup, setShowAddContactPopup] = useState<any>(false);
  const [selectContactErr, setSelectContactErr] = useState<any>("");
  const [contactFlag, setContactFlag] = useState(false);
  const [files, setFile] = useState<File>();
  const [fileErrorMsg, setFileErrorMsg] = useState("")
  const [errorFlag, setErrorFlag] = useState(false);
  const [importType, setImportType] = useState<any>("");
  const [selectedTab, setSelectedTab] = useState<any>("my_contact");

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    formState: { errors: errors1 },
    clearErrors: clearErrors1,
    setError: setError1,
    reset: reset1,
    watch: watch1,
  } = useForm();

  let watchVariable1 = watch1();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2 },
    clearErrors: clearErrors2,
    setError: setError2,
    reset: reset2,
    watch: watch2,
  } = useForm();

  let watchVariable2 = watch2();
  const [myContactpage, setMyContactPage] = useState(1);
  const [selectedContact, setSelectedContact] = useState<any>({
    id: "",
    value: "",
    label: "Select user",
  });

  useEffect(() => {
    getMyContext(myContactpage, selectedTab);
    setShowMyContextPopup(props.showMyContextPopup)
  }, [props.showMyContextPopup, myContactpage]);



  useEffect(() => {
    getMyContext(1, selectedTab);
  }, [keyword1]);

  useEffect(() => {
    setBulkContactDelete([]);
    setMyContextCheckList([]);
    setMyContextList([]);
  }, [props.showMyContextPopup]);


  const getMyContext = (page: any, tab: any) => {
    var obj: any = {
      "page": page,
      "keyword": keyword1,
      "uia_id": props.uiaId,
      "is_my_contact": props.isMyContact,
      "my_contact": tab === "my_contact" ? true : false,
      "org_contact": tab === "org_contact" ? true : false,
      "other_contact": tab === "other_contact" ? true : false
    }
    setContactLoader(true);
    var qurey = new URLSearchParams(obj);
    return WebService.getAPI({
      action: `imessage/user-contacts?` + qurey,
      // page=${page}&keyword=${keyword1}&uia_id=${props.uiaId}&is_my_contact=${props.isMyContact}`,
      body: null,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedList = res.list.map((post: any) => {
            return {
              ...post,
              check: false,
              myContextCheck: false,
            };
          });
          setMyContextList(updatedList);
        } else {
          setMyContextList([]);
        }
        setMyContactCount(res.count);
        setContactLoader(false);

      })
      .catch((error: any) => {
        setContactLoader(false);
        return error;
      });
  };


  const handleMyContextCheckboxChange = (clickedPost: any) => {
    if (clickedPost.myContextCheck === false) {
      var arr = [...myContextCheckList];
      arr.push(clickedPost.id);
      setMyContextCheckList(arr);

      if (myContextCheckList.length === myContextList.length) {
        setallSelectForMyContext(true);
      }
      if (myContextCheckList.length === myContextList.length) {
        setAllSelectMyContext(true);
      }
    } else {
      var arr = myContextCheckList.filter((id) => id !== clickedPost.id);
      setMyContextCheckList(arr);
      setallSelectForMyContext(false);
    }
    setMyContextList((prevList: any) =>
      prevList.map((post: any) =>
        post.id === clickedPost.id
          ? { ...post, myContextCheck: !post.myContextCheck }
          : post
      )
    );
    setBulkContactDelete(arr);
    if (arr.length > 0) {
      setIsShowDeleteBtn(true);
    } else {
      setIsShowDeleteBtn(false);
    }
  };
  const handleBulkContactDelete = () => {
    WebService.addLoader("contact-delete-btn");
    var body =
    {
      "idList": bulkContactDelete,
      "userId": userId
    }
    return WebService.postAPI({
      action: `imessage/delete-contacts`,
      body: body,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        setKeyword1("");
        toast.success(res.message);
        WebService.removeLoader("contact-delete-btn");
        setShowContactDelete(false);
        setIsShowDeleteBtn(false);
        getMyContext(myContactpage, selectedTab);
        setBulkContactDelete([]);
        setMyContextCheckList([]);
        setMyContextList([]);

      })
      .catch((error: any) => {
        WebService.removeLoader("contact-delete-btn");
        return error;
      });
  }

  const onUserSelection = (selectedOption: any) => {
    setSelectContactErr("");
    setSelectedContact(selectedOption);
  };

  const changePhone = (value: any) => {
    setValue1("refId", value);
    watchVariable1.refId = value;
    setContactFlag(false);
  };


  const handleAddContact = () => {
    if (props.userId === "" || props.userId === undefined) {
      if ((selectedContact.id === "" || selectedContact.id === undefined) && role !== "BASIC_USER") {
        setSelectContactErr("Please select iMessage user");
        return false;
      }
    }

    if (watchVariable1.refId === "" || watchVariable1.refId === undefined) {
      setContactFlag(true);
      return false;
    }
    if (watchVariable1.refId.split("").length == 1) {
      setContactFlag(true);
      return false;
    }
    WebService.addLoader("add-contact-save-btn");
    var body =
    {
      "number": "+" + watchVariable1.refId,
      "firstName": watchVariable1.firstName,
      "lastName": watchVariable1.lastName,
      "userId": props.userId ? props.userId : selectedContact.id
    }
    return WebService.postAPI({
      action: `imessage/add-contacts`,
      body: body,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        setSelectedContact({
          id: "",
          value: "",
          label: "Select iMessage user",
        });
        toast.success(res.message);
        WebService.removeLoader("add-contact-save-btn");
        setShowAddContactPopup(false);
        getMyContext(myContactpage, selectedTab);
        reset1();
      })
      .catch((error: any) => {
        WebService.removeLoader("add-contact-save-btn");
        return error;
      });

  }
  const userPromiseOption = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      var body = {
        "socialType": props.type,
        "accountType": "",
        "accountStatus": "",
        "myConnector": false,
        "idList": []
      }
      return WebService.postAPI({
        action:
          `user-integration/connectors?page=1&keyword=` + inputValue,
        body: body,
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res.list.length > 0) {
            var updatedOptions = res.list
              .filter((user: any) => (user.accountStatus === "ACTIVE" || user.accountStatus === "CONFIGURATION_PENDING" || user.accountStatus === "CONNECTION_ERROR"))
              .map((user: any, index: any) => ({
                id: user.userId,
                value: index,
                label: user.firstName + " " + user.lasttName + " " + "(" + user.refId + ")",
              }));
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });
  const handleError1 = () => {
    if ((selectedContact.value == "" || selectedContact.value == undefined) &&
      (watchVariable1.refId === "" || watchVariable1.refId === undefined)
    ) {
      setContactFlag(true);
      setSelectContactErr("Please select iMessage user");
      return false;
    }
  }


  const handleAllSelectForMyContext = (checkeStatus: any) => {
    const updatedList = myContextList.map((post: any) => ({
      ...post,
      myContextCheck: checkeStatus,
    }));

    const selectedIds = updatedList
      .filter((post: any) => post.myContextCheck)
      .map((post: any) => post.id);

    setallSelectForMyContext(checkeStatus);
    setMyContextList(updatedList);
    setMyContextCheckList(selectedIds);
    setBulkContactDelete(selectedIds);
    if (selectedIds.length > 0) {
      setIsShowDeleteBtn(true);
    } else {
      setIsShowDeleteBtn(false);
    }

  };

  useEffect(() => {
    if (!showMyContextPopup) {
      props.changeStatus();
    }
  }, [showMyContextPopup]);


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setValue2("fileImport", e.target.files[0]);
      setErrorFlag(false);
      setFileErrorMsg("");
    }
  };

  const handleUploadClick = () => {
    if (!files) {
      setErrorFlag(true);
      setFileErrorMsg("Please select csv file");
      return;
    }

    if (files?.type != "text/csv") {
      setErrorFlag(true);
      setFileErrorMsg("File format should be csv")
      return;
    }

    setErrorFlag(false);
    setFileErrorMsg("");
    var type = role == "ACCOUNT_ADMIN" ? "ALL" : "CURRENT";
    WebService.addLoader("import_btn");
    return WebService.uploadAPI({
      action: `import/user-contacts?type=${importType}`,
      body: null,
      _file: files,
      key: "file",
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        getMyContext(myContactpage, selectedTab);
        WebService.removeLoader("import_btn");
        toast.success(res.message);
        setShowImsgImportPopup(false);
        setImportType("");
      })
      .catch((error) => {
        WebService.removeLoader("import_btn");
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        }
        return [];
      });
  };

  return (
    <>
      <Offcanvas
        show={showMyContextPopup}
        onHide={() => { setShowMyContextPopup(false) }}
        placement="end"
        className="righSide-modal size-md"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            My Contacts
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Form
          className="w-100 form-style"
          name="Verify"
          id="Verify"
        >
          <OffcanvasBody>
            <Row>
              <Col>
                <InputGroup className="addContact-search-box">
                  <InputGroup.Text id="basic-addon1">
                    <IoSearchOutline className="icon" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder='Search'
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setKeyword1(e.currentTarget.value);
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col>
                <div className="d-flex text-nowrap gap-1 justify-content-end">
                  {
                    bulkContactDelete?.length > 0 && isShowDeleteBtn ?
                      <Button
                        id="bulk-delete-btn"
                        className="btn-brand-1 mb-2 me-2"
                        onClick={
                          () => {
                            setShowContactDelete(true);
                          }}
                      >
                        Bulk Delete
                      </Button>
                      : ""
                  }
                  {
                    role == "ACCOUNT_ADMIN" ?
                      <Dropdown className="dropdown-toggle2 me-1">
                        <Dropdown.Toggle
                          id=""
                          variant=""
                          className="btn-brand-light mb-2"
                        >
                          <TbCloudDownload size={18} className="me-1" />
                          <span  >Import</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setImportType("ALL");
                              setErrorFlag(false);
                              setFileErrorMsg("");
                              setFile(undefined);
                              setShowImsgImportPopup(true)
                            }}
                          >
                            Organization Contact
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setImportType("CURRENT");
                              setErrorFlag(false);
                              setFileErrorMsg("");
                              setFile(undefined);
                              setShowImsgImportPopup(true)
                            }}
                          >
                            Private Contact
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      :
                      <Button
                        id="bulk-delete-btn"
                        className="btn-brand-light mb-2 me-2"
                        onClick={() => {
                          setImportType("CURRENT");
                          setErrorFlag(false);
                          setFileErrorMsg("");
                          setFile(undefined);
                          setShowImsgImportPopup(true)
                        }}
                      >
                        <TbCloudDownload size={18} className="me-1" />
                        Import
                      </Button>
                  }

                  <Button
                    id="bulk-delete-btn"
                    className="btn-brand-light mb-2"
                    onClick={() => {
                      setShowAddContactPopup(true);
                      reset1();

                    }}
                  >
                    + Add Contact
                  </Button>
                </div>
              </Col>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey={selectedTab}>
              <Row className="justify-content-between mb-3">
                <Col md={4}>
                  <Nav variant="pills" className="tab-style-1" style={{ backgroundColor: "#f5f5f5" }}>
                    <Nav.Item>
                      <Nav.Link eventKey="my_contact"
                        onClick={() => {
                          setSelectedTab("my_contact");
                          getMyContext(1, "my_contact");
                        }}>
                        My Contacts
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="org_contact" onClick={() => {
                        setSelectedTab("org_contact");
                        getMyContext(1, "org_contact");
                      }}>Organization Contacts</Nav.Link>
                    </Nav.Item>
                    {
                      role === "ACCOUNT_ADMIN" &&
                      <Nav.Item>
                        <Nav.Link eventKey="other_contact" onClick={() => {
                          setSelectedTab("other_contact");
                          getMyContext(1, "other_contact");
                        }}>Other Contacts</Nav.Link>
                      </Nav.Item>
                    }
                  </Nav>
                </Col>
              </Row>
              <Tab.Content>
                <Tab.Pane
                  eventKey="my_contact"
                // onClick={() => {
                //   setSelectedTab("my_contact");
                //   getMyContext(1, "my_contact");
                // }}
                >
                </Tab.Pane>
                <Tab.Pane
                  eventKey="org_contact"
                // onClick={() => {
                //   setSelectedTab("org_contact");
                //   getMyContext(1, "org_contact");
                // }}
                >
                </Tab.Pane>
                <Tab.Pane
                  eventKey="other_contact"
                // onClick={() => {
                //   setSelectedTab("other_contact");
                //   getMyContext(1, "other_contact");
                // }}
                >
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>

            <div className="table-card table-container">
              <Table className="table-style">
                {
                  myContextList.length > 0 ?
                    <thead>
                      <tr>
                        <th className="d-flex text-nowrap">
                          <Form.Check
                            type="checkbox"
                            id="selectAll1"
                            checked={allSelectForMyContext}
                            onClick={() => {
                              handleAllSelectForMyContext(!allSelectForMyContext);
                            }}
                            label=""
                          />
                          <span > Number</span>
                        </th>
                        <th className="">Contact Name</th>
                        <th className="">User</th>
                        <th className="action-col cursor-pointer">Action</th>
                      </tr>
                    </thead>
                    : ""
                }

                <tbody>
                  {!contactLoader ? (
                    <>
                      {myContextList && myContextList.length > 0 ? (
                        myContextList.map((contact: any) => {
                          const isRowActive =
                            selectedPost && selectedPost.id === contact.id;
                          return (
                            <tr
                              className={`${contact.reviewClass} ${isRowActive ? "active-row" : ""
                                }`}
                            >
                              <td className="text-nowrap">
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  checked={contact.myContextCheck}
                                  id={contact.id}
                                  onChange={() => {
                                    handleMyContextCheckboxChange(contact);
                                  }}
                                />
                                {contact.number}
                              </td>
                              <td>
                                <span >
                                  {contact.name}
                                </span>
                              </td>
                              <td>
                                <span >
                                  {contact.userId !== 0 ?
                                    contact.firstName + " " + contact.lastName :
                                    contact.clientName
                                  }
                                </span>
                              </td>
                              <td>
                                <div className="action-btns text-center">
                                  <Button
                                    className="action-btn btn-delete"
                                    title="Delete"
                                    onClick={() => { setBulkContactDelete([contact.id]); setShowContactDelete(true) }}
                                  >
                                    <PiTrash className="icon" />
                                  </Button>
                                </div>{" "}
                              </td>
                            </tr>
                          );
                        })
                      ) : (

                        <div className="table-wrap position-relative w-100" style={{ marginTop: "200px" }}>
                          <div className="no-data-found w-100">
                            <div className="text-center">
                              <img
                                src={NoDataFound}
                                width={110}
                                className="mb-3"
                                alt={""}
                              />
                              <h3 className="text-secondary font-12">
                                No Record Found
                              </h3>
                            </div>
                          </div>
                        </div>

                      )}
                    </>
                  ) : (
                    <div style={{ marginTop: "200px", marginLeft: "240px" }}>
                      <TableLoader />
                    </div>

                  )}
                </tbody>
              </Table>
              {
                !contactLoader ?
                  <AIPagination
                    pagination={pagination}
                    totalItems={myContactCount}
                    changePage={(page: number) => {
                      setMyContactPage(page);
                    }}
                    changeCurrentPage={myContactpage}
                  />
                  : ""
              }
            </div>
          </OffcanvasBody>
        </Form>
      </Offcanvas >

      <Modal show={showContactDelete} onHide={() => setShowContactDelete(false)} centered>
        <Modal.Header
          closeButton
          onClick={() => setShowContactDelete(false)}
          className=" border-0 pb-0"
        ></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="text-danger">
              <PiSealWarning size={70} />
            </h2>
            <h4 className="font-18">
              Are you sure you want to delete?
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer className=" justify-content-center border-0 pt-0">
          <Button className="btn-brand-light" onClick={() => setShowContactDelete(false)} >
            No
          </Button>
          <Button className="btn-brand-1" id="contact-delete-btn" onClick={() => handleBulkContactDelete()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Offcanvas
        show={showAddContactPopup}
        onHide={() => { setShowAddContactPopup(false) }}
        placement="end"
        className="righSide-modal size-md"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Add Contact
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Form
          className="w-100 form-style"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit1(handleAddContact, handleError1)}
        >
          <OffcanvasBody>
            {
              ((props.uiaId && props.userId) || role === "BASIC_USER") ? "" :
                role !== "BASIC_USER" ?
                  <Col lg={12} className="mb-3">
                    <AsyncSelect
                      {...register1("userId")}
                      cacheOptions
                      defaultOptions
                      onChange={onUserSelection}
                      loadOptions={userPromiseOption}
                      defaultValue={selectedContact}
                    />
                    <span className="text-danger font-12">{selectContactErr}</span>
                  </Col>
                  : ""
            }

            <Col lg={12} className="mb-3">
              <PhoneInput
                country={"us"}
                // value={mobile}
                onChange={changePhone}
                specialLabel=""
                placeholder="Mobile number"
              />
              {
                contactFlag
                  ? (
                    <Label
                      title={"Please enter contact number"}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  ) : (
                    ""
                  )}
            </Col>
            <Row lg={12}>
              <Col lg={6}>
                <FloatingLabel
                  controlId="firstName"
                  label="First Name"
                  className="mb-2"
                >
                  <input
                    {...register1("firstName", {
                      required: "Please enter first name",
                    })}
                    type="text"
                    className="form-control ps-0 "
                    placeholder="First Name"
                    onChange={(e) => {
                      setValue1("firstName", e.target.value);
                      watchVariable1.firstName = e.target.value
                    }}
                  />
                  {errors1.firstName && (
                    <Label
                      title={errors1.firstName.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </FloatingLabel>
              </Col>
              <Col lg={6}>
                <FloatingLabel
                  controlId="lastName"
                  label="Last Name"
                  className="mb-2"
                >
                  <input
                    {...register1("lastName", {
                      required: "Please enter last name",
                    })}
                    type="text"
                    className="form-control ps-0 "
                    placeholder="Last Name"
                    onChange={(e) => {
                      setValue1("lastName", e.target.value);
                      watchVariable1.lastName = e.target.value
                    }}
                  />
                  {errors1.lastName && (
                    <Label
                      title={errors1.lastName.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </FloatingLabel>
              </Col>
            </Row>

            <div className="mt=2 ">
              <Button
                type="submit"
                className="btn btn-brand-1 w-100"
                id="add-contact-save-btn"
              >
                Save
              </Button>
            </div>
          </OffcanvasBody>
        </Form>
      </Offcanvas >

      <Offcanvas show={showImsgImportPopup} onHide={() => setShowImsgImportPopup(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Import</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form className="form-style"
            onSubmit={handleSubmit2(handleUploadClick)}
          >
            <div className="upload-btn-container mb-2">
              <label htmlFor="fileImport"><PiUploadSimple size={18} className="me-3" />{files ? files?.name : "Upload"}</label>
              <input
                {...register2("fileImport", { required: false })}
                type="file"
                id="fileImport"
                accept=" "
                onChange={handleFileChange}
              />
            </div>
            <Row>
              <Col className="font-12 text-secondary">
                *You can upload csv files
                {errorFlag ? (
                  <Label title={fileErrorMsg} modeError={true} />
                ) :
                  ""
                }
              </Col>
            </Row>
            <div>
              <Button type="submit" id="import_btn" className="btn btn-brand-1 w-100 mb-2">Import</Button>
            </div>
            <div style={{ "textAlign": "end", marginBottom: "10px" }}>
              <a
                className="font-12"
                style={{ color: "#0000FF", cursor: "pointer" }}
                href={`${process.env.PUBLIC_URL}/imsg_sample.csv`}
                download="imessage-sample-file.csv"
              >
                Download sample file
              </a>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UserContact;







